const futToOpt = {
  ZC: "OZC",
  ZS: "OZS",
  ZW: "OZW",
  ZL: "OZL",
  ZM: "OZM",
  MWE: "OMW",
  ZO: "OZO",
  ZR: "OZR",
  NG: "LN",
  CL: "LO",
  HO: "OH",
  BZ: "BZO",
  RB: "OB",
  RTY: "RTO",
  "6E": "EUU",
  "6C": "CAU",
  "6B": "GBU",
  "6J": "JPU",
  "6A": "ADU",
  "6S": "CHU",
  GC: "OG",
  SI: "SO",
  HG: "HXE",
  PL: "PO",
  PA: "PAO",
  MET: "VM",
  MBT: "WM",
};

export default futToOpt;
